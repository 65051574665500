import './Datatable.css'

import * as React from 'react'

import { Checkbox, FormControlLabel, MenuItem, Pagination, PaginationItem, Select, SvgIcon, TableCell } from '@mui/material'
import { TableDetailCell, TableHeaderCell } from '../../../model/models-module'

import ActionMenu from './ActionsMenu'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ReactComponent as CheckboxIcon } from '../../../assets/icons/checkbox.svg'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { extractHierachyValue } from '../../../utils/CommonUtils'
import { NotResultTable } from '../not-results/NotResultTable'

type Props = {
  state?: string
  className?: string
  children?: React.ReactNode
  appendOnScrollToBottom?: boolean
  selectable?: boolean
  pageable?: boolean
  pageSelected?: number
  countItems?: number
  rowsPerPage?: number
  headers: TableHeaderCell[]
  rows: TableDetailCell[]
  hasActions?: boolean
  groupActions?: boolean
  dataCheckedList?: Map<number, any>
  onPageSizeChange?(pageSize: number): void
  onPageChange?(page: number): void
  onSelectRow?(row: any): void
  onSelectAllRows?(rows: any[]): void
  onScrollToBottom?(nextBlockSize: number): void
  onSortChange?(prop: string, orientation: string): void
  onSelectableChange?(selectedRows: Map<number, any>): void
  showEmptyItems?: boolean
}

export default function DataTable({
  rows,
  headers,
  selectable = false,
  pageable = false,
  pageSelected = 1,
  countItems = 0,
  rowsPerPage = 15,
  onSortChange,
  onPageChange,
  onPageSizeChange,
  appendOnScrollToBottom = false,
  onScrollToBottom,
  hasActions = false,
  groupActions = true,
  onSelectableChange,
  onSelectRow,
  onSelectAllRows,
  className = '',
  dataCheckedList = new Map(),
  showEmptyItems = true
}: Props) {
  // ref
  const tableRef = React.useRef<HTMLDivElement>(null)
  const [lastPos, setLastPos] = React.useState<number>(0)
  const [sort, setSort] = React.useState<number>(0)
  const [dataChecked, setDataChecked] = React.useState<Map<number, any>>(dataCheckedList)
  const [pageSize, setPageSize] = React.useState<number>(rowsPerPage)
  const [page, setPage] = React.useState<number>(pageSelected)
  const [newSort, setNewSort] = React.useState<Map<string, string>>(new Map())
  const [dataRows, setDataRows] = React.useState<TableDetailCell[]>(rows ?? [])
  const [dataHeaders, setDataHeaders] = React.useState<TableHeaderCell[]>(headers ?? [])
  const [checkAll, setCheckAll] = React.useState<boolean>(dataRows.length === dataChecked.size)

  const [pagesCount, setPagesCount] = React.useState<number>(0)


  const setNewOrder = (header: TableHeaderCell) => {
    switch (header.sort) {
      case '':
        header.sort = 'ASC'
        break
      case 'ASC':
        header.sort = 'DESC'
        break
      case 'DESC':
        header.sort = ''
        break
      default:
        header.sort = 'ASC'
        break
    }

    setNewSort(new Map([[header.key, header.sort]]))
  }

  React.useEffect(() => {
    if (onSelectableChange) {
      onSelectableChange(dataChecked)
    }
  }, [dataChecked])
  // }, [dataChecked, onSelectableChange])

  React.useEffect(() => {
    setCheckAll(dataRows.length === dataChecked.size)
  }, [dataRows, dataChecked])

  const addRemoveSelection = (row: any, idx: number) => {
    if (onSelectRow!)
      onSelectRow(row)
    // eslint-disable-line @typescript-eslint/no-explicit-any

    const mapAux = new Map()
    dataChecked.forEach((value, key) => mapAux.set(key, value))

    if (dataChecked.has(idx)) {
      mapAux.delete(idx)
    } else {
      mapAux.set(idx, row)
    }
    setDataChecked(mapAux)
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    if (onPageChange) {
      onPageChange(value)
      setDataChecked(new Map())
    }
  }

  const validateAndScroll = (el: HTMLElement) => {
    setLastPos(el.scrollTop)

    // if(el && (el.offsetHeight + el.scrollTop) >= (el.scrollHeight - .5)) {// Le resto lo que tiene de margin el scroll.
    if (el && Math.ceil(el.scrollTop) + 10 >= el.scrollHeight - el.offsetHeight) {
      if (dataRows.length < countItems) {
        const nextBlock =
          dataRows.length + pageSize < countItems ? dataRows.length + pageSize : countItems
        onScrollToBottom!(nextBlock)
      }
    }
  }

  const onScrollEvent = (event: React.UIEvent<HTMLElement, UIEvent>) => {
    if (!appendOnScrollToBottom) {
      return
    }
    const el: HTMLElement = event.target as HTMLElement
    validateAndScroll(el)
  }

  const onCheckAll = React.useCallback((pCheckAll) => {
    if (onSelectAllRows!) {
      onSelectAllRows(pCheckAll ? dataRows : [])
    }
    const mapAux = new Map()
    if (pCheckAll) {
      dataRows.forEach((r, i) => {
        mapAux.set(i, r)
      })
    } else {
      mapAux.clear()
    }
    setDataChecked(mapAux)
    setCheckAll(pCheckAll)
  }, [dataRows, dataChecked, checkAll])

  React.useEffect(() => {
    if (onSortChange && sort > 0) {
      onSortChange!(newSort.keys().next().value, newSort.values().next().value)
    }
  }, [newSort, sort])

  React.useEffect(() => {
    if (
      tableRef != null &&
      tableRef != undefined &&
      dataRows.length > 0 &&
      appendOnScrollToBottom
    ) {
      setTimeout(() => {
        validateAndScroll(tableRef!.current!)
      }, 1)
    }
  }, [tableRef, dataRows])

  React.useEffect(() => {
    setDataHeaders(headers)
  }, [headers])

  React.useEffect(() => {
    setPage(pageSelected)
  }, [pageSelected])

  React.useEffect(() => {
    if (
      dataRows.length > 0 &&
      lastPos > 0 &&
      tableRef.current &&
      tableRef.current!.scrollTop === 0
    ) {
      setTimeout(() => {
        tableRef.current!.scrollTop = lastPos
        if (tableRef.current!.scrollTop != lastPos) {
          setLastPos(0)
        }
      }, 1)
    }
  }, [dataRows, setLastPos])

  React.useEffect(() => {
    setPagesCount(Math.ceil(countItems / pageSize))
  }, [pageSize, countItems])

  React.useEffect(() => {
    setDataRows(rows)
  }, [rows])

  const handlePaginationTextLabel = (): string => {
    if (countItems === 0) return 'Mostrando 0 entradas de un total de 0'
    return `Mostrando ${(page - 1) * pageSize + 1} a ${page * pageSize > countItems ? countItems : page * pageSize
      } entradas de un total de ${countItems}`
  }

  return (
    <>
      {countItems > 0 || !showEmptyItems ?
        <div>
          <TableContainer
            component={Paper}
            className={
              appendOnScrollToBottom
                ? 'newsan-table nwsn-scroll scrolleable ' + className
                : 'newsan-table nwsn-scroll' + className
            }
            onScroll={onScrollEvent}
            ref={tableRef}
          >
            <Table sx={{ minWidth: 700 }} aria-label='customized table'>
              <TableHead>
                <TableRow key='header'>
                  {selectable ? (
                    <TableCell key='selectHeader'>
                      <span style={{ height: '30px', top: '0px' }}>
                        <Checkbox
                          style={{ top: '0px', margin: '0px', padding: '0px' }}
                          size='medium'
                          checked={checkAll}
                          onChange={(e, val: boolean) => onCheckAll(val)}
                        />
                      </span>
                    </TableCell>
                  ) : null}
                  {dataHeaders.map((header: TableHeaderCell, index) => (
                    <TableCell
                      key={`${header.label}${index}`}
                      className={`text-${header.align ?? 'left'}`}
                    >
                      <span style={header.headerStyle} className='data-header'>
                        <span className={`header-title text-${header.align ?? 'left'}`}>
                          {header.label}
                        </span>
                        {header.sorteable ? (
                          <span
                            key={header.key}
                            onClick={() => {
                              setNewOrder(header)
                              setSort(sort + 1)
                            }}
                          >
                            <i className={header.sort == 'ASC' ? 'sort-active' : ''}>
                              <ArrowDropUpOutlinedIcon></ArrowDropUpOutlinedIcon>
                            </i>
                            <i className={header.sort == 'DESC' ? 'sort-active' : ''}>
                              <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon>
                            </i>
                          </span>
                        ) : null}
                      </span>
                    </TableCell>
                  ))}
                  {hasActions ? (
                    <TableCell key='actionsHeader'>
                      <span style={{ minWidth: '20px' }}></span>
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              {dataRows ? (
                <TableBody>
                  {dataRows.map((row, index) => (
                    <TableRow key={`row${index}`}>
                      {selectable ? (
                        <TableCell key={`check${index}`} className='checkbox-datatable'>
                          <span>

                            <Checkbox
                              style={{ position: 'relative', top: '5px' }}
                              checked={dataChecked.has(index)}
                              size='medium'
                              onChange={(e, val: boolean) => addRemoveSelection(row, index)}
                            />
                            {/* <SvgIcon
                              onClick={() => addRemoveSelection(row, index)}
                              className={dataChecked.has(index) ? 'checked' : ''}
                              component={CheckboxIcon}
                              viewBox='0 0 18 22'
                            /> */}
                          </span>
                        </TableCell>
                      ) : null}

                      {headers.map((header: TableHeaderCell) => (
                        <TableCell key={`row${header.key}${index}`}>
                          <span style={header.colStyle ?? {}}>
                            {row.customDefinitions != undefined &&
                              row.customDefinitions.has(header.key) &&
                              row.customDefinitions.get(header.key) ? (
                              // <span className='custom-element'>
                              <>{row.customDefinitions.get(header.key)}</>
                              // </span>
                            ) : (
                              extractHierachyValue({ data: row.data, props: header.key })
                            )}
                          </span>
                        </TableCell>
                      ))}

                      {row.actions != undefined && row.actions.length > 0 ? (
                        <TableCell>
                          {
                            <ActionMenu
                              actions={row.actions}
                              index={index}
                              row={row}
                              group={groupActions}
                            ></ActionMenu>
                          }
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          {pageable && !appendOnScrollToBottom &&
            <div className='col-12' style={{ position: 'relative', marginTop: '20px' }}>
              <div className='col-3' style={{ display: 'inline-block', width: '25%' }}>
                <label htmlFor='select' className='lbl-rows-per-page'>
                  Mostrar
                </label>
                <Select
                  labelId='select'
                  id='select'
                  className='select-container'
                  value={pageSize}
                  onChange={(v) => {
                    setPageSize(Number(v.target.value))
                    onPageSizeChange!(Number(v.target.value))
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </div>
              <div
                className='col-4 items-showed-legend'
                style={{ display: 'inline-block', width: '35%', textAlign: 'right' }}
              >
                {handlePaginationTextLabel()}
              </div>
              <div className='col-4' style={{ position: 'absolute', right: '10px', top: '10px' }}>
                <Pagination
                  className='custom-pagination'
                  count={pagesCount}
                  page={page}
                  onChange={handleChange}
                  size='large'
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                      {...item}
                    />
                  )}
                ></Pagination>
              </div>
            </div>
          }
        </div>
        : <NotResultTable />
      }
    </>
  )
}
