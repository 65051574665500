import BaseService, { FindAllType } from './BaseService'


export default class ReportingService extends BaseService {

  private static _instance: ReportingService
  baseEndpoint = 'reporting'


  public static getInstance(setError: (message: string | null) => void): ReportingService {
    if (!this._instance) {
      this._instance = new this(setError)
    }
    return this._instance
  }

  private constructor(setError: (message: string | null) => void) {
    super(setError)
  }

  async downloadExcel(lang: string, idList: number[]) {
    try {
      const ids = idList.join(',')
      const result = await this.client({ url: `/${this.baseEndpoint}/${lang}/key-issues/excel?ids=${ids}`, method: 'GET', responseType: 'blob' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async downloadComparisonToolExcel(lang: string, body: any) {
    try {
      const result = await this.client({ url: `/${this.baseEndpoint}/${lang}/comparison/excel`, data: body, method: 'POST', responseType: 'blob' })

      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }

  async downloadDoc(lang: string, html: any) {
    try {
      const result = await this.client({ url: `/${this.baseEndpoint}/${lang}/convert-to/word`, data: { htmlContent: html }, method: 'POST', responseType: 'blob' })

      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }

  async downloadPdf(lang: string, html: any) {
    try {
      const result = await this.client({ url: `/${this.baseEndpoint}/${lang}/convert-to/pdf`, data: { htmlContent: html }, method: 'POST', responseType: 'blob' })

      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }


  async findConsolidateGrid({ lang, args = '', limit = 10, offset, sort }: FindAllType) {
    try {
      const url = `/${this.baseEndpoint}/${lang}/consolidate`;
      return await this.baseFindAll({ lang, args, limit, offset, sort }, url)

    } catch (err: any) {
      throw await this.handleError(err)
    }

  }

}
